<template>
<div>
<template v-if="!submitted">
  <form class="reg-form ">
       <div class="section-heading mb-40 text-center">
                   <h2>BETA Registration</h2>
                   <br><span>BETA Registration is open! <br>If you want early access to community development from the onset please join us!</span>
               </div>
  <div class="form-group row">
    <label for="first" class="col-4 col-form-label">First Name</label> 
    <div class="col-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fa fa-address-card"></i>
          </div>
        </div> 
        <input v-model="first" id="first" name="first" placeholder="First Name" type="text" class="form-control form-input">
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="last" class="col-4 col-form-label">Last Name</label> 
    <div class="col-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fa fa-address-card-o"></i>
          </div>
        </div> 
        <input v-model="last" id="last" name="last" placeholder="Last Name" type="text" class="form-control form-input">
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="email" class="col-4 col-form-label">Email</label> 
    <div class="col-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fa fa-at"></i>
          </div>
        </div> 
        <input v-model="email" id="email" name="email" placeholder="Email" type="text" class="form-control form-input">
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="emailconfirmed" class="col-4 col-form-label">Email Confirmed</label> 
    <div class="col-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fa fa-at"></i>
          </div>
        </div> 
        <input v-model="emailconfirmed" id="emailconfirmed" name="emailconfirmed" placeholder="Email Confirmed" type="text" class="form-control form-input">
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="zipcode" class="col-4 col-form-label">Zip Code</label> 
    <div class="col-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fa fa-address-card-o"></i>
          </div>
        </div> 
        <input v-model="zipcode" id="zipcode" name="zipcode" placeholder="Zip Code" type="text" class="form-control form-input">
      </div>
    </div>
  </div> 
  <div class="form-group row">
    <div class="offset-4 col-8">
      <button name="submit" type="button" class="default-btn"  v-on:click="submit">Submit</button>
    </div>
  </div>
  
</form>
</template>
<template v-else>
    <div class="reg-form ">
        <div class="section-heading mb-40 text-center">
                   <h2> Registered!</h2>
                   <br><span>{{zipcolde}} is an area we are excited to engage! <br>Please check your email for information regarding access to your BETA account completion.</span>
               </div>
         </div>
</template>
</div>
</template>

<script>
import axios from "axios";
export default {
  name: 'register',
  data() {
    return {
      submitted: false,
      email: "",
      emailconfirmed: "",
      first: "",
      last: "",
      zipcode: ""
    };
  },
  methods: {
    submit: function () {
        
      axios
        .post("/routes/beta/create", {
          email: this.email,
          first: this.first,
          last: this.last,
          zipcode: this.zipcode
        })
        .then( (response) => {
          console.log(response);
          if (response.data.success == true) {
            this.submitted = true;
            toastr.success('SUCCESS', 'You are now registered!')
          } else
           {
              toastr.error('ERROR', 'Please contact support@wetheveto.com')
           }
        })
        .catch( (error) => {
          console.log(error);
        });
    },
  },
}
</script>

<style scoped>
</style>