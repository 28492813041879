<template>
     <section class="pricing-section bg-dark padding bd-bottom">
            <div class="container">
                <div class="col-md-12 table-info table-dark-info">
                    <div class="table-responsive">
                        <table class="table table-dark">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Audience</th>
                                    <th scope="col">$</th>
                                </tr>
                            </thead>
                            <tbody class="th-color">
                                <tr class="green">
                                    <th scope="row">Community Builder</th>
                                    <td>API and Application Access to services for organizing meaningful.</td>
                                    <td>Everyone</td>
                                    <td>FREE</td>
                                </tr>
                                <tr class="green">
                                    <th scope="row">Community Builder Premier</th>
                                    <td>API and Application Access to services for organizing meaningful impact with organization promotion and support.</td>
                                    <td>Thought leaders and business owners</td>
                                    <td>$500 Month / Zip Code</td>
                                </tr>
                                <tr class="green">
                                    <th scope="row">Community Insights</th>
                                    <td>Analytic services showcasing community organization with the power of voting on community impact items.</td>
                                    <td>Community Participants</td>
                                    <td>Free for primary Zip Code</td>
                                </tr>
                                <tr class="green">
                                    <th scope="row">Community Insights</th>
                                    <td>Analytic services showcasing community organization with the power of voting on community impact items.</td>
                                    <td>Community Participants</td>
                                    <td>$5 Month / additional Zip Codes</td>
                                </tr>
                                <tr class="green">
                                    <th scope="row">Community Insights</th>
                                    <td>Analytic services showcasing community organization with the power of voting on community impact items from a macro level to nationwide</td>
                                    <td>News Media, Political Paries, Research Institures</td>
                                    <td>$1500 Month / Zip Code</td>
                                </tr>
                                <tr class="green">
                                    <th scope="row">Community Insights</th>
                                    <td>Analytic services showcasing community organization with the power of voting on community impact items from a macro level to nationwide</td>
                                    <td>News Media, Political Paries, Research Institures</td>
                                    <td>State Wide, Nation Wide - <a class="nav-link active" href="mailto:support@wetheveto.com?subject=Support Request">Contact Us</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
  name: 'pricing',
}
</script>

<style scoped>
</style>