<template>
  <section class="widget-section padding">
    <div class="container">
      <div class="widget-wrap row">
        <div class="col-lg-4 col-sm-12 sm-padding">
          <div class="widget-content">
            <a href="#"
              ><img
                src="assets/img/wtv.logo.spread.png"
                alt="img"
                style="height: 72px"
            /></a>
            <p>Stay up to date with all of our latest news and updates!</p>
            <div class="subscribe-box clearfix">
              <div class="subscribe-form-wrap">
                <template v-if="!submitted">
                  <form
                    action="/routes/news/create"
                    class="subscribe-form"
                    method="post"
                  >
                    <input
                      v-model="email"
                      type="email"
                      name="email"
                      id="subs-email"
                      class="form-input"
                      placeholder="Enter Your Email Address..."
                    />
                    <button
                      type="button"
                      class="submit-btn"
                      v-on:click="submit"
                    >
                      <i class="fa fa-paper-plane"></i>
                    </button>
                    <div id="subscribe-result">
                      <p class="subscription-success"></p>
                      <p class="subscription-error"></p>
                    </div>
                  </form>
                </template>
                <template v-else> THANK YOU! </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4 sm-padding">
          <h3>Company</h3>
          <ul class="widget-info">
            <li><router-link to="/register" data-scroll class="nav-link active">BETA Register</router-link></li>
            <li><a class="nav-link active" href="mailto:support@wetheveto.com?subject=Support Request">Contact Us</a> </li>
            <li><router-link to="/privacy" data-scroll class="nav-link active">Privacy Policy</router-link></li>
            <li><router-link to="/terms" data-scroll class="nav-link active">Terms & Conditions</router-link></li>
          </ul>
        </div>
        <!--
                    <div class="col-lg-4 col-sm-4 sm-padding">
                        <div class="widget-header">
                            <h3>Contact With Us</h3>
                            <p>Our support available to help you 24 hours a day <br>&amp; 7 days a week.</p>
                        </div>
                    </div>
                    -->
        <!--
                    <div class="col-lg-2 col-sm-4 sm-padding">
                        <h3>Realated Links</h3>
                        <ul class="widget-info">
                            <li><a href="#">What is ICO</a></li>
                            <li><a href="#">Services</a></li>
                            <li><a href="#">Prices</a></li>
                            <li><a href="#">Roadmap</a></li>
                            <li><a href="#">Our Team</a></li>
                        </ul>
                    </div>
                    -->
      </div>
    </div>
  </section>
  <!--/.widget-section-->
</template>

<script>
import axios from "axios";

export default {
  name: "contact",
  data() {
    return {
      submitted: false,
      email: "",
    };
  },
  methods: {
    submit: function () {
        this.submitted = true;
      axios
        .post("/routes/news/create", {
          email: this.email,
        })
        .then(function (response) {
          console.log(response);
          if (response.data.success == true) {
            
            toastr.success('SUCCESS', 'You are now registered!')
          } else
           {
              toastr.error('ERROR', 'Please contact support@wetheveto.com')
           }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
</style>