<template>
<div class="reg-form ">
    <div class="section-heading mb-40 text-center">
        <h2>Privacy Policy</h2>
    </div>
   <div class="">
        <div class="div-block-15">
            <div class="text-block-9">WeTheVeto, a Flatland Outlaw Companies LLC Venture built the WeTheVeto technology stack as a free and paid app. This SERVICE is
                provided by WeTheVeto is intended for use as is.​<br><br>This page is used
                to inform visitors regarding our policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service.​<br><br>If you choose to use our Service, then you
                agree to the collection and use of information in relation to this policy. The Personal Information that
                we collect is used for providing and improving the Service. We will not use or share your information
                with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions, which is accessible at WeTheVeto unless otherwise defined
                in this Privacy Policy.<br></div>
            <h3 class="heading-6">Information Collection and Use</h3>
            <div class="text-block-8">For a better experience, while using our Service, we may require you to provide us
                with certain personally identifiable information, including but not limited to full name, email address, zipcode.
            </div>
            <h3 class="heading-7">Log Data</h3>
            <div class="text-block-7">We want to inform you that whenever you use our Service, in a case of an error in
                the app we collect data and information (through third party products) on your phone called Log Data.
                This Log Data may include information such as your device Internet Protocol (“IP”) address, device name,
                operating system version, the configuration of the app when utilizing our Service, the time and date of
                your use of the Service, and other statistics.</div>
            <h3 class="heading-8">Cookies</h3>
            <div class="text-block-6">Cookies are files with a small amount of data that are commonly used as anonymous
                unique identifiers. These are sent to your browser from the websites that you visit and are stored on
                your device's internal memory.​<br><br>This Service does not use these “cookies” explicitly. However,
                the app may use third party code and libraries that use “cookies” to collect information and improve
                their services. You have the option to either accept or refuse these cookies and know when a cookie is
                being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions
                of this Service.</div>
            <h3 class="heading-9">Service Providers</h3>
            <div class="text-block-3">We may employ third-party companies and individuals due to the following reasons:
            </div>
            <ul role="list" class="list-2">
                <li>To facilitate our Service</li>
                <li>To provide the Service on our behalf</li>
                <li>To perform Service-related services</li>
                <li>To assist us in analyzing how our Service is used</li>
            </ul>
            <div class="text-block-5">We want to inform users of this Service that these third parties have access to
                your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However,
                they are obligated not to disclose or use the information for any other purpose.</div>
            <h3 class="heading-10">Security</h3>
            <div class="text-block-4">We value your trust in providing us your Personal Information, thus we are
                striving to use commercially acceptable means of protecting it. But remember that no method of
                transmission over the internet, or method of electronic storage is 100% secure and reliable, and we
                cannot guarantee its absolute security.​</div>
            <h3 class="heading-11">Links To Other Sites</h3>
            <div class="text-block-10">This Service may contain links to other sites. If you click on a third-party
                link, you will be directed to that site. Note that these external sites are not operated by us.
                Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control
                over and assume no responsibility for the content, privacy policies, or practices of any third-party
                sites or services.​</div>
            <h3 class="heading-12">Children's Privacy </h3>
            <div class="text-block-11">These Services do not address anyone under the age of 13. We do not knowingly
                collect personally identifiable information from children under 13 years of age. In the case we discover
                that a child under 13 has provided us with personal information, we immediately delete this from our
                servers. If you are a parent or guardian and you are aware that your child has provided us with personal
                information, please contact us so that we will be able to do necessary actions.​</div>
            <h3 class="heading-13">Changes To This Privacy Policy</h3>
            <div class="text-block-12">We may update our Privacy Policy from time to time. Thus, you are advised to
                review this page periodically for any changes. We will notify you of any changes by posting the new
                Privacy Policy on this page.​<br><br>This policy is effective as of 2021-11-01</div>
            <h3 class="heading-14">Contact Us</h3>
            <div class="text-block-13">If you have any questions or suggestions about our Privacy Policy, do not
                hesitate to contact us at <a href="mailto:support@wetheveto.com" class="link-3">support@wetheveto.com</a>.
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'privacy',
}
</script>

<style scoped>
</style>
