<template>
  <div id="app">
    <navbar></navbar>
    <div class="bg-dark">
      <router-view />
    </div>
    <contact></contact>
    <wtvfooter></wtvfooter>
  </div>
</template>

<script>
import navbar from './components/mainnav.vue';
import home from './components/home.vue';
import contact from './components/contact.vue';
import wtvfooter from './components/wtvfooter.vue';
import register from './components/register.vue';
import privacy from './components/privacy.vue';
import term from './components/terms.vue';

export default {
  components: { home, navbar, contact, wtvfooter, register, privacy, term },
  name: "app"
};
</script>