<template>
    <div>
         <section class="hero-section bg-dark bd-bottom d-flex align-items-center pos-rel">
           <div class="bg-pattern"></div>
            <div class="container">
                <div class="hero-wrap row align-items-center">
                    <div class="col-md-6">
                        <div class="hero-content">
                            <h1><span style="color:rgba(0,153,255,1)">We</span><span style="color:rgb(253, 7, 7)">The</span><span style="color:rgba(0,153,255,1)">Veto</span></h1>
                            <p>Bringing the power back to the people with their voice.</p>
                            <router-link to="/register" class="default-btn" >Register Now</router-link>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="hero-img mt-20">
                            <img src="assets/img//community.lite.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="service-section bg-dark padding bd-bottom">
            <div class="container">
               <div class="section-heading mb-40 text-center">
                   <h2>Our Mission</h2>
                   <br>Provide technology for uniting voices, educating, and bringing together a strong community organization for change<br>
                with the most innovative technologies and modern ideas.</span>
               </div>
                <div class="service-wrap row">
                    <div class="col-lg-4 col-sm-6 padding-15">
                        <div class="service-content text-center">
                           <div class="service-content-inner">
                               <i class="icon-chat"></i>
                                <h3>Community</h3>
                                <p>WeTheVeto provides tools and technology services to bring together like minded people together around ideas and inititives for real change.</p>
                           </div>
                            <div class="hover-shape-1"></div>
                            <div class="hover-shape-2"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 padding-15">
                       <div class="service-content text-center">
                           <div class="service-content-inner">
                            <i class="icon-book-open"></i>
                                <h3>Knowledge</h3>
                                <p>WeTheVeto a wealth of community provided news, analytics for community development.  Community approved reources are vital.</p>
                           </div>
                            <div class="hover-shape-1"></div>
                            <div class="hover-shape-2"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 padding-15">
                        <div class="service-content text-center">
                           <div class="service-content-inner">
                               <i class="icon-trophy"></i>
                                <h3>Recognition</h3>
                                <p>As WeTheVeto believes in giving power back to the people we want to empower community participants instead of profiting from them.</p>
                           </div>
                            <div class="hover-shape-1"></div>
                            <div class="hover-shape-2"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 padding-15">
                        <div class="service-content text-center">
                           <div class="service-content-inner">
                                <i class="icon-target"></i>
                                <h3>True Analytic</h3>
                                <p>WeTheVeto is working hard to provide truthful and meaningful representation compared to so much untruth in main stream media of all forms.</p>
                           </div>
                            <div class="hover-shape-1"></div>
                            <div class="hover-shape-2"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 padding-15">
                        <div class="service-content text-center">
                           <div class="service-content-inner">
                                <i class="icon-mic"></i>
                                <h3>A Voice</h3>
                                <p>WeTheVeto believes in the freedom of speech and will provide communities the ability to moderate their own stanards in respect of this.</p>
                           </div>
                            <div class="hover-shape-1"></div>
                            <div class="hover-shape-2"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 padding-15">
                        <div class="service-content text-center">
                           <div class="service-content-inner">
                                <i class="icon-map-pin"></i>
                                <h3>The Path</h3>
                                <p>WeTheVeto is on a path for launching various services, dapps, and oracle data.  Embracing technology and business models for our users is priority.</p>
                           </div>
                            <div class="hover-shape-1"></div>
                            <div class="hover-shape-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="roadmap-section padding bg-dark bd-bottom pos-rel">
           <div class="bg-pattern"></div>
            <div class="container">
               <div class="section-heading mb-50 text-center">
                   <h2>Road Map</h2>
                   <span>WeTheVeto is aggressive about embracing business and technology models that accomplish the overall mission while leveraging modern technologies that empower and reward our users and supporters.</span>
               </div>
                <div class="roadmap-wrap row">
                    <div class="col-lg-2 col-md-4 col-sm-6 sm-padding">
                        <div class="roadmap-content text-center">
                            <i class="fa fa-check"></i>
                            <h3>March 2009</h3>
                            <p>WeTheVeto Idea Born</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 sm-padding">
                        <div class="roadmap-content text-center">
                            <i class="fa fa-check"></i>
                            <h3>November 2020</h3>
                            <p>Alpha Platform Development</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 sm-padding">
                       <div class="road-icon text-center">
                           <i class="fa fa-circle roadmap-animation"></i>
                       </div>
                        <div class="roadmap-content-2 text-center">
                           <div class="arrow-up"></div>
                            <h3>December 2020</h3>
                            <p>Beta Request Open</p>
                            <span>Now</span>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 sm-padding">
                        <div class="roadmap-content text-center">
                            <i class="fa fa-circle"></i>
                            <h3>February 2021</h3>
                            <p>Beta Access wetheveto.io launch</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 sm-padding">
                        <div class="roadmap-content text-center">
                            <i class="fa fa-circle"></i>
                            <h3>April 2021</h3>
                            <p>dapp powered by wetheveto.io launched</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 sm-padding">
                        <div class="roadmap-content text-center">
                            <i class="fa fa-circle"></i>
                            <h3>June 2021</h3>
                            <p>Public Release</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="chart-section padding bg-dark bd-bottom">
            <div class="container">
                <div class="row d-flex align-items-center">
                   <div class="col-lg-6 sm-padding">
                        <div class="chart-content">
                            <h2><span style="color:rgba(0,153,255,1)">We</span><span style="color:rgb(253, 7, 7)">The</span><span style="color:rgba(0,153,255,1)">Veto</span> ends the divide<br>and brings the power of the people to governance.</h2>
                            <p>No matter which party you support most Americans feel like there is a disconnect between what our elected officials promote and vote on compared to what communities want as well as individuals.</p>
                            <p>This is a problem that happens at every level of government that we aim to correct with your voice.</p>
                            <p>WeTheVeto provides elected officials from your zipcode to the highest levels of government and allows you to vote on the same issues they are working on.</p>
                            <p>Elected officials will have a direct window into what their constituents desires are on everything and provide report cards for those elected officials.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 sm-padding">
                        <div class="chart-wrap">
                            <img src="assets/img/chart.svg" alt="Chart">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="faq-section padding bg-dark bd-bottom">
            <div class="container">
                <div class="section-heading mb-40 text-center">
                    <h2>Frequently Asked Questions!</h2>
                    <span>WeTheVeto provides technology services that are promoting the voices of our users through community development and education.</span>
                </div>
                <div class="faq-wrap row">
                    <div class="col-md-6 col-sm-6">
                        <ul class="faq-list list-2">
                            <li>
                               <span>Q.</span>
                                <h3>What is the Beta registration?</h3>
                                <p>Beta registration is open to all that would like to participate in shaping the initial release of WeTheVeto<br>backend services.  Early adopters will be eligible for special releases and airdrops.</p>
                            </li>
                            <li>
                               <span>Q.</span>
                                <h3>What is this?</h3>
                                <p>WeTheVeto is a collection of technology to empower the voice of people through community organization<br>and development with decentralized technology and oracle data software sources.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <ul class="faq-list">
                            <li>
                               <span>Q.</span>
                                <h3>What is the application road map built on wetheveto.io?</h3>
                                <p>Subscribe to our news and upcoming discord server for complete details.<br>Users whom register for Beta access now will have priority for all future apps and airdrops.</p>
                            </li>
                            <li>
                               <span>Q.</span>
                                <h3>How can I help?</h3>
                                <p>We are looking for users to register who:
                                    <br>-Want to make a difference in their community
                                    <br>-Want to participate in a voice in governance with transparency
                                    <br>-Want to contribute to the development of new dapps and ideas
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        
        
        <contact></contact>
		<footer></footer>
    </div>
</template>
<script>
module.exports = {
    data: function(){
        return {
            greeting: "HELLO"
        }
    }
}
</script>

<style scoped>
</style>