import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import Home from './components/home.vue';
import Register from './components/register.vue';
import Pricing from './components/pricing.vue';
import Privacy from './components/privacy.vue';
import Terms from './components/terms.vue';
export default new Router({
  mode: "history",
  routes: [
    { path: '/', component: Home },
    { path: '/home', component: Home },
    { path: '/register', component: Register },
    { path: '/pricing', component: Pricing },
    { path: '/privacy', component: Privacy },
    { path: '/terms', component: Terms },
  ]
});