<template>
    <footer class="footer-section align-center">
			<div class="container">
				<p>&copy; 2009-2021 WeTheVeto - A Flatland Outlaw Companies LLC Venture</p>
			</div>
		</footer>
</template>

<script>
export default {
  name: 'wtvfooter',
}
</script>

<style scoped>
</style>