<template>
  <div class="reg-form">
    <div class="section-heading mb-40 text-center">
      <h2>Terms and Conditions</h2>
    </div>
    <div class="">
      <div class="div-block-15">
        <div class="text-block-9">
          By downloading or using the services or applications in the technology stack hereforth referred to as SERVICES, these terms will automatically apply
          to you – you should make sure therefore that you read them carefully
          before using the SERVICES. You’re not allowed to copy, or modify the SERVICES,
          any part of the data, service structures, or our trademarks in any way. You’re not allowed
          to attempt to extract the source code of the SERVICES, and you also
          shouldn’t try to translate the SERVICES into other languages, or make
          derivative versions. The SERVICES itself, and all the trade marks,
          copyright, database rights and other intellectual property rights
          related to it, still belong to WeTheVeto and Flatland Outlaw Companies LLC.​<br /><br />WeTheVeto is committed to ensuring that the SERVICES are as useful and
          efficient as possible. For that reason, we reserve the right to make
          changes to the SERVICES or to change charges for its services, at any time and for
          any reason. We will never charge you for the app or its services
          without making it very clear to you exactly what you’re paying
          for.​<br /><br />The WeTheVeto SERVICES store and processe personal data
          that you have provided to us, in order to provide our Service. It’s
          your responsibility to keep your phone and access to the SERVICES secure.
          <br />The SERVICES do
          use third party services that declare their own Terms and
          Conditions.​<br /><br />If you’re using the SERVICES outside of an area
          with Wi-Fi, you should remember that your terms of the agreement with
          your mobile network provider will still apply. As a result, you may be
          charged by your mobile provider for the cost of data for the duration
          of the connection while accessing the app, or other third party
          charges. In using the app, you’re accepting responsibility for any
          such charges, including roaming data charges if you use the app
          outside of your home territory (i.e. region or country) without
          turning off data roaming. If you are not the bill payer for the device
          on which you’re using the app, please be aware that we assume that you
          have received permission from the bill payer for using the app.​<br /><br />Along
          the same lines, WeTheVeto cannot always take
          responsibility for the way you use the app i.e. You need to make sure
          that your device stays charged – if it runs out of battery and you
          can’t turn it on to avail the Service, WeTheVeto cannot
          accept responsibility.​<br /><br />With respect to WeTheVeto’s responsibility for your use of the SERVICES, when you’re
          using the SERVICES, it’s important to bear in mind that although we
          endeavour to ensure that it is updated and correct at all times, we do
          rely on third parties to provide information to us so that we can make
          it available to you. WeTheVeto accepts no liability for
          any loss, direct or indirect, you experience as a result of relying
          wholly on this functionality of the SERVICES.​At some point, we may wish to
          update the SERVICES.  You
          promise to always accept updates to the SERVICES when offered to
          you, We may also wish to stop providing the SERVICES, and may terminate use
          of it at any time without giving notice of termination to you. Unless
          we tell you otherwise, upon any termination, (a) the rights and
          licenses granted to you in these terms will end; (b) you must stop
          using the SERVICES, and (if needed) delete it from your device or credentials for accessing SERVICES.​<br />
        </div>
        <h2 class="heading-6">Changes To These Terms &amp; Conditions</h2>
        <div class="text-block-8">
          We may update our Terms and Conditions from time to time. Thus, you
          are advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Terms and Conditions on
          this page.​<br /><br />These terms and conditions are effective as of
          2021-11-01<br />
        </div>
        <h2 class="heading-14">Contact Us</h2>
        <div class="text-block-13">
          If you have any questions or suggestions about our Terms and
          Conditions, do not hesitate to contact us at
          <a href="mailto:support@wetheveto.com">support@wetheveto.com</a
          >.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "terms",
};
</script>

<style scoped>
</style>
