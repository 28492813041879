<template>
    <header id="header" class="header-section">
            <div class="container">
                <nav class="navbar ">
                     <router-link to="/home" class="navbar-brand"><img class="wtvlogo" src="assets/img/wtv.logo.spread.png" alt="WTV - WE THE VETO"></router-link>
                   <div class="d-flex menu-wrap">
                       <div id="mainmenu" class="mainmenu">
                           <ul class="nav">
                               <li><router-link to="/home" data-scroll class="nav-link active" >Home<span class="sr-only">(current)</span></router-link> </li>
                               <li><router-link to="/register" data-scroll class="nav-link active" >Register<span class="sr-only">(current)</span></router-link> </li>
                               <li><router-link to="/pricing" data-scroll class="nav-link active" >Service Pricing<span class="sr-only">(current)</span></router-link> </li>
                            </ul>
                       </div>
                    </div>
                </nav>
            </div>
		</header> 
</template>

<script>
export default {
  name: 'navbar',
}
</script>

<style scoped>
</style>